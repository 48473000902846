import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Mapa = ({data}) => (
  <StyledContainer>
    <h1 className={font.financierMediumItalic}>{data.title}</h1>
    <div className="info">
      <div className="infoTxt">
        {data.schedule.map((data, i)=>(<p key={i} className={font.nexaRegularItalic}>{data.days} <span className={font.nexaXBold}>{data.hours}</span></p>))}
      </div>
      <div className="infoTxt">
      {data.phone.map((data, i) => (<div style={{ marginBottom: "16px" }}><a href={"tel:" + data.ind + " " + data.number} key={i} className={font.nexaRegularItalic}>{data.ind}<span className={font.nexaXBold}> {data.number}</span><br></br><small style={{fontSize: "7px"}}>{data.txt}</small></a></div>))}      </div>
      <div className="infoTxt">
        <a href={"mailto:" + data.email} className={font.nexaXBold}>{data.email}</a>
      </div>
      <div className="infoTxt">
        {data.address.map((data, i)=>(<a href="https://www.google.com/maps/place/IPMD/@40.6444453,-8.630737,15z/data=!4m2!3m1!1s0x0:0x7997a5a6efca764e?sa=X&ved=2ahUKEwiq66OkzNLsAhWBZMAKHaVADwMQ_BIwGnoECCQQBQ" target="_blank" rel="noreferrer nofollow" key={i} className={font.nexaXBold}>{data}</a>))}
      </div>
    </div>
    <iframe src="https://snazzymaps.com/embed/210219" title="Mapa" width="100%" height="100%" style={{border: "none"}}></iframe>
  </StyledContainer>
)

export default Mapa

const StyledContainer = styled.div`
  margin-top: 7rem;
  h1{color: #e6e6e6; width: 90%; margin: 2vw auto; text-align: center;}
  iframe{width: 100%; height: 80vw; background-color: #e6e6e6;}
  .info{
    margin: 3vw 0;
    color: #717171;
    padding: 0 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .infoTxt{margin: 1.2em 0; width: 55%;}
  }
`