import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Horario = ({data}) => (
  <StyledContainer>
    <h5 className={font.nexaBold + " title"}>{data.title}</h5>
    <div className="flex">
      {data.schedule.map((data, i)=>(
        <div className="card" key={i}>
          <p className={font.nexaHeavy + " big"}>{data.title}</p>
          <p className={font.nexaRegular + " small"}>{data.start}</p>
          <p className={font.nexaRegular + " small"}>{data.end}</p>
        </div>
      ))}
    </div>
  </StyledContainer>
)

export default Horario

const StyledContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  .title{text-transform: uppercase;letter-spacing: 0.3em; color: #717171; margin-bottom: 2rem;}
  .flex{
    display: flex;
    justify-content: space-between;
    .card{
      background-color: #efefef;
      width: 12%;
      min-width: fit-content;
      text-align: center;
      padding: 0.8rem 0.5rem;
      border-radius: 25%;
      .big{margin-bottom: 0.5rem;}
      .small{color: #17d4e3;}
    }
  }
`