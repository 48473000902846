import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import seta from "../images/svg/Seta_Direita1.svg"

const Form = ({data}) => {

  return(
    <StyledForm>
      <h1 className={font.financierMediumItalic}>{data.title}</h1>
      <div className="blue">
        <div className="img">
          <Img fluid={data.img.childImageSharp.fluid} style={{width: `100%`, height: `40vw`}} imgStyle={{objectFit: "cover"}}/>
        </div>
        <div className="box">
          <div className="text">
            <p className={font.nexaRegular}>{data.text}</p>
          </div>
          <div className="forms">
            {data.form.map((data, i)=>(
              <div key={"reserva" + i}>
                <p className={font.nexaRegular + " form"}>{data}</p>
                <hr className="line"/>  
              </div>
            ))}
          </div>
          <Link className="btn" to={"/formacao"}>
            <p className={font.nexaRegularItalic}>SAIBA MAIS</p>
            <div className="btnImg">
              <img src={seta} alt="seta"/>
            </div>
          </Link>
        </div>
      </div>
    </StyledForm>
  )
}


export default Form

const StyledForm = styled.div`
  position: relative;
  margin-top: 15vh;
  .title{
    text-align: center;
    margin-bottom: 2em;
    color: #717171;
    text-transform: uppercase;
    letter-spacing: 0.3em;
  }
  .btn{
    margin: 4em auto 0;
    display: flex;
    align-items: center;
    width: fit-content;
    .btnImg{
      position: relative;
      margin-left: 1em;
      img{
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
  .box{
    color: #fff;
    position: relative;
    background-color: #02374e;
    padding: 8vw;
  }
  .forms{
    margin-top: 6vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: calc(1rem + 10 * (100vw - 1000px) / 920);
    grid-column-gap: calc(1rem + 10  * (100vw - 1000px) / 920);
    text-align: center;
    .form{letter-spacing: 0.3em;}
  }
  h1{font-size: calc(50px + 60 * (100vw - 769px) / 1791); color: #e6e6e6; text-align: center; margin: 0 0 1em;}
  p{font-size: calc(12px + 4 * (100vw - 1000px) / 920); line-height: 1.7em;}
  .line{
    width: 40%;
    border: none;
    margin: 1em auto;
    border-top: #fff 1px solid;
  }
`