import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import InfoBox from "./infoBox"

import seta from "../images/svg/Seta_Direita1.svg"
import setaH from "../images/svg/Seta_Direita2.svg"

const Form = ({data}) => {
  const [btn, setBtn] = useState(false);

  return(
    <StyledForm>
      <InfoBox data={data.img}>
        <div className="text">
          <p className={font.nexaRegular}>{data.text}</p>
        </div>
        <div className="subtitle">
          <h1 className={font.financierMediumItalic}>{data.title}</h1>
          <Link className="btn" to={"/formacao"} onMouseEnter={()=>{setBtn(true)}} onMouseLeave={()=>{setBtn(false)}}>
            <p className={font.nexaRegularItalic}>SAIBA MAIS</p>
            <div className="btnImg">
              <CSSTransition in={btn} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={setaH} alt="seta"/>
              </CSSTransition>
              <CSSTransition in={!btn} timeout={350} classNames={"switch"} unmountOnExit>
                <img src={seta} alt="seta"/>
              </CSSTransition>
            </div>
          </Link>
        </div>
        <div className="forms">
          {data.form.map((data, i)=>(
            <div key={"reserva" + i}>
              <p className={font.nexaRegular + " form"}>{data}</p>
              <hr className="line"/>  
            </div>
          ))}
        </div>
      </InfoBox>
    </StyledForm>
  )
}


export default Form

const StyledForm = styled.div`
  position: relative;
  margin-top: calc(15rem + 100 * (100vw - 1000px) / 920);
  margin-bottom: 200px;
  .title{
    position: absolute;
    left: calc(50% + (1rem + 32 * (100vw - 1000px) / 920));
    top: -10%;
    color: #717171;
    text-transform: uppercase;
    letter-spacing: 0.3em;
  }
  .btn{
    margin-top: 4em;
    display: flex;
    align-items: center;
    width: fit-content;
    .btnImg{
      position: relative;
      margin-left: 1em;
      img{
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
  .forms{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: calc(1rem + 10 * (100vw - 1000px) / 920);
    grid-column-gap: calc(1rem + 10  * (100vw - 1000px) / 920);
    text-align: center;
    .form{letter-spacing: 0.3em;}
  }
  .subtitle{h1{font-size: calc(1.5rem + 44.5 * (100vw - 1000px) / 920);}}
  p{font-size: calc(12px + 4 * (100vw - 1000px) / 920); line-height: 1.7em;}
  .line{
    width: 40%;
    border: none;
    margin: 1em auto;
    border-top: #fff 1px solid;
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`