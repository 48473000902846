import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Mapa = ({ data }) => (
  <StyledContainer>
    <h1 className={font.financierMediumItalic}>{data.title}</h1>
    <div className="mapFlex">
      <iframe src="https://snazzymaps.com/embed/210219" title="Mapa" width="100%" height="100%" style={{ border: "none" }}></iframe>
      <div className="info">
        <div className="infoTxt">
          {data.schedule.map((data, i) => (<p key={i} className={font.nexaRegularItalic}>{data.days} <span className={font.nexaXBold}>{data.hours}</span></p>))}
        </div>
        <div className="infoTxt">
          {data.phone.map((data, i) => (<div style={{ marginBottom: "16px" }}><a href={"tel:" + data.ind + " " + data.number} key={i} className={font.nexaRegularItalic}>{data.ind}<span className={font.nexaXBold}> {data.number}</span><br></br><small>{data.txt}</small></a></div>))}        </div>
        <div className="infoTxt">
          <a href={"mailto:" + data.email} className={font.nexaXBold}>{data.email}</a>
        </div>
        <div className="infoTxt">
          {data.address.map((data, i) => (<a href="https://www.google.com/maps/place/IPMD/@40.6444453,-8.630737,15z/data=!4m2!3m1!1s0x0:0x7997a5a6efca764e?sa=X&ved=2ahUKEwiq66OkzNLsAhWBZMAKHaVADwMQ_BIwGnoECCQQBQ" target="_blank" rel="noreferrer" key={i} className={font.nexaXBold}>{data}</a>))}
        </div>
      </div>
    </div>
  </StyledContainer>
)

export default Mapa

const StyledContainer = styled.div`
  margin-top: 7rem;
  h1{color: #e6e6e6; width: 25%; margin: 3rem 30%;}
  .mapFlex{
    display: flex;
    iframe{width: 50%; height: 30vw; background-color: #e6e6e6;}
    .info{
      color: #717171;
      padding: 5rem 3rem;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .infoTxt{margin: 1.2rem 0; width: 55%;}
    }
  }
`