import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Intro from "../components/intro"
import PedidoMarcacao from "../components/glassPedido"
import Horario from "../components/contactosHorario"
import Mapa from "../components/contactosMapa"
import CallNow from "../components/callnow"
import Reserva from "../components/contactosReserva"
import Footer from "../components/footer"

import HeaderMobile from "../components/headerMobile"
import ConsultaMobile from "../components/consultaMobile"
import HorarioMobile from "../components/contactosHorarioMobile"
import MapaMobile from "../components/contactosMapaMobile"
import ReservaMobile from "../components/contactosReservaMobile"
import TestemunhosMobile from "../components/testemunhosMobile"
import FooterMobile from "../components/footerMobile"

const FormacaoPage = ({data}) => (
  <Layout>
    <SEO title="Contactos" />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile header={data.globalJson.header}/>
        <Intro data={data.contactosJson.intro} mobile={true}/>
        <ConsultaMobile open={true} />
        <HorarioMobile />
        <MapaMobile data={data.contactosJson.mapa} />
        <CallNow />
        <ReservaMobile data={data.contactosJson.reserva}/>
        <TestemunhosMobile data={data.globalJson.testemunhos}/>
        <FooterMobile data={data.globalJson.footer}/>
      </>
      ) : (
      <>
        <Header header={data.globalJson.header}/>
        <Intro data={data.contactosJson.intro}/>
        <PedidoMarcacao data={data.contactosJson.pedido}/>
        <Horario data={data.contactosJson.horario} />
        <Mapa data={data.contactosJson.mapa} />
        <CallNow/>
        <Reserva data={data.contactosJson.reserva}/>
        <Footer data={data.globalJson.footer} />
      </>)
    }
  </Layout>
)

export default FormacaoPage

export const Json = graphql`
  query contactos {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      testemunhos{
        title
        subtitle
        quotes{
          text
          author
        }
      }
      footer{
        logo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
    }
    contactosJson {
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
      pedido{
        title
        subtitle
        name
        phone
        email
        observations
        send
        btnImage{
          childImageSharp {
            fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      horario{
        title
        schedule{
          title
          start
          end
        }
      }
      mapa{
        title
        schedule{
          days
          hours
        }
        phone{
          ind
          number
          txt
        }
        email
        address
        btn{
          childImageSharp {
            fluid(quality: 70, maxWidth: 100) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        btnTxt
        callNow
      }
      reserva{
        img{
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        text
        btnImg{
          childImageSharp {
            fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        btnTxt
        form
      }
    }
  }
`