import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Horario = ({data}) => (
  <StyledContainer>
    <h5 className={font.nexaBold + " title"}>HORÁRIO</h5>
    <div className="flex">
        <div className="card">
          <p className={font.nexaHeavy + " big"}>SEG - SEX</p>
          <p className={font.nexaRegular + " small"}>9H00</p>
          <p className={font.nexaRegular + " small"}>19H30</p>
        </div>
        <div className="card">
          <p className={font.nexaHeavy + " big"}>SÁB</p>
          <p className={font.nexaRegular + " small"}>9H00</p>
          <p className={font.nexaRegular + " small"}>15H30</p>
        </div>
    </div>
  </StyledContainer>
)

export default Horario

const StyledContainer = styled.div`
  width: 80%;
  margin: 40px auto;
  .title{text-transform: uppercase;letter-spacing: 0.3em; color: #717171; margin-bottom: 2rem;}
  .flex{
    display: flex;
    justify-content: center;
    .card{
      margin: 0 2em;
      background-color: #efefef;
      width: fit-content;
      text-align: center;
      padding: 1.5em 2em;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .big{margin-bottom: 0.5rem;}
      .small{color: #17d4e3;}
    }
  }
`